<template lang="pug">
  v-container
    v-row(
      justify="center"
      v-if="hasLoaded"
    )
      v-col(
        cols="12"
      )
        v-row.mt-2
          v-col(
            cols="3"
          )
            v-img.brand(
              height="120"
              src="@/assets/ASP_logo_no_text.png"
              @load="hasImageLoaded = true"
              contain
            )
          v-col.ml-auto(
            cols="6"
          )
            v-row(
              style="margin: 0px;"
            )
              v-col.ml-auto(
                cols="4"
                style="border: 2px solid black; border-right: none; padding: 0;"
              )
                h1.text-h5.mb-2.px-1 Date
                p.text-h5.mb-2.px-1(
                  style="border-top: 2px solid black;"
                ) {{invoice.formattedDate}}
              v-col(
                cols="4"
                style="border: 2px solid black; padding: 0;"
              )
                h1.text-h5.mb-2.px-1 Invoice #
                p.text-h5.mb-2.px-1(
                  style="border-top: 2px solid black;"
                ) {{invoice.invoiceNumber}}
        v-col.mb-6(
          cols="12"
        )
          v-row(
            justify="space-between"
          )
            v-col(
              cols="3"
              style="border: 2px solid black; padding: 0; margin-top: 3rem;"
            )
              h1.text-h5.mb-1.px-1(
                style="border-bottom: 2px solid black;"
              ) Customer Information
              p.ma-0.px-1 {{invoice.shop.name}}
              p.text-caption.ma-0.px-1 {{invoice.shop.address1}}
              p.text-caption.ma-0.px-1 {{invoice.shop.city}}, {{invoice.shop.state}}, {{invoice.shop.zip}}
            v-col(
              cols="4"
              style="margin-top: 3rem;"
            )
              h1.text-h5.mb-1 ASP International, Inc
              p.ma-0 3251 Country Road 321
              p.text-caption.ma-0 Bertram, TX 78605

        v-row.ml-auto(
          style="border: 2px solid black; width: 15rem; margin: 0px; margin-bottom: -2px;"
        )
          v-col(
            cols="6"
            style="padding: 0;"
          )
            h1.text-h5.mb-1.px-1(
              style="border-bottom: 2px solid black"
            ) RO
            p.px-1.mb-0 {{ invoice.roNumber }}

          v-col(
            cols="6"
            style="border-left: 2px solid black; padding: 0;"
          )
            h1.text-h5.mb-1.px-1(
              style="border-bottom: 2px solid black"
            ) Tech
            p.px-1.mb-0 {{ invoice.tech_id ? invoice.tech.name : 'None' }}

        v-data-table.clickable.invoice-table(
          :headers="headers"
          no-data-text="No Products Found"
          disable-pagination
          :items="rows"
          @click:row=""
          hide-default-footer
          :items-per-page="-1"
        )
          template(#item.listPrice="{ item: product }")
            span ${{ Number(product.listPrice).toFixed(2) | round(2) }}
          template(#item.listSubtotal="{ item: product }")
            span ${{ Number(product.listPrice * product.qty).toFixed(2) | round(2) }}
      v-layout(
        flex
        justify-space-between
        style="margin-right: 12px;"
      )
        h4.pl-4 {{ listTotalQuantity }}
          span:p.ml-2 Total Items
        h4.pa-1(
          style="border: 2px solid black; margin: 0px;"
        ) Total Price: ${{Number(invoice.listPriceTotal).toFixed(2)}}
</template>

<script>
import { useGet, useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

const accumulator = (accumulator, currentValue) => accumulator + currentValue
export default {
  name: 'Invoice',
  props: {
    invoiceId: {
      type: String,
      required: true
    }
  },
  setup (props, context) {
    const { Invoice } = context.root.$FeathersVuex.api

    const params = computed(() => {
      return { query: { $eager: 'tech', $mergeEager: 'shop' } }
    })

    // Get the patient record
    const { item: invoice, hasLoaded } = useGet({
      params,
      model: Invoice,
      id: props.invoiceId
    })

    const { InvoiceRow } = context.root.$FeathersVuex.api

    const rowsParams = computed(() => {
      const query = {
        query: {
          invoice_id: props.invoiceId,
          $limit: 100
        }
      }

      return query
    })

    const { items: rows, isPending: areRowsPending, haveLoaded } = useFind({
      model: InvoiceRow,
      params: rowsParams
    })

    return {
      rows,
      haveLoaded,
      areRowsPending,
      invoice,
      hasLoaded
    }
  },
  watch: {
    haveBothLoaded: function (value) {
      setTimeout(function () { window.print() }, 30)
    }
  },
  computed: {
    haveBothLoaded () {
      return this.hasLoaded && this.haveLoaded && this.hasImageLoaded
    },
    listTotalQuantity () {
      if (!this.rows.length) {
        return 0
      }
      const listQuantity = this.rows.map(row => row.qty)
      const listTotalQuantity = listQuantity.reduce(accumulator)

      return listTotalQuantity
    }
  },
  data: () => ({
    hasImageLoaded: false,
    headers: [
      {
        text: 'Qty.',
        value: 'qty'
      },
      {
        text: 'Item',
        value: 'name'
      },
      {
        text: 'Description',
        value: 'description'
      },
      {
        text: 'Price',
        value: 'listPrice'
      },
      {
        text: 'Subtotal',
        value: 'listSubtotal'
      }
    ]
  })
}
</script>
<style lang="scss">
  .brand {
    -webkit-print-color-adjust: exact;
  }
  .v-data-table-header > tr > th{
    border-top: 2px solid black;
  }
  .v-data-table__wrapper > table > thead > tr:last-child > th:first-child {
    border-left: 2px solid black;
  }
  .v-data-table__wrapper > table > thead > tr:last-child > th:last-child {
    border-right: 2px solid black;
  }
  .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: 2px solid black !important;
  }
</style>
